/*eslint-disable*/
import { useCallback, useState } from 'react';
import type { ChangeEvent, FC, MouseEvent } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Card,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  TableContainer,
  CircularProgress,
  Dialog,
  List,
  Typography,
  Divider,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Tab,
  Tabs
} from '@material-ui/core';
import PencilAltIcon from '../../../../icons/PencilAlt';
import SearchIcon from '../../../../icons/Search';
import getInitials from '../../../../utils/getInitials';
import Scrollbar from '../../../../components/Scrollbar';
import { debounce, isEqual } from 'lodash';
import Label from 'src/components/Label';
import PreviewIcon from '@material-ui/icons/Preview';
import { useTheme } from '@material-ui/core/styles';
import { userChatSessions } from 'src/admin/api/user';
import {
  getChatsBySessionId,
  getConversationBySessionAndChatId,
} from 'src/api/chat';
import CloseIcon from 'src/icons/sessionClose.png';
import {
  TableCellStyle,
  TableHeaderCellStyle,
} from '../content/DocumentListTable';
import { ChatMessage, ChatDocumentViewer } from 'src/components/dashboard/chat';
import { useSelector, useDispatch } from 'src/store';
import { toggleIsDocumentViewerOpen, setThreadMountedStatus } from 'src/slices/chat';
import { HierarchialListItem } from './HierarchialListItem';
import toast from 'react-hot-toast';

interface UsersListTableProps {
  users: any;
  loading: boolean;
  getUsers: any;
  tableParams: { page: number; limit: number; query: string };
  setTableParams: React.Dispatch<
    React.SetStateAction<{ page: number; limit: number; query: string }>
  >;
}

const dateFormatOptions: Intl.DateTimeFormatOptions = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' };

const UsersListTable: FC<UsersListTableProps> = (props) => {
  const { users, loading, getUsers, tableParams, setTableParams, ...other } =
    props;
  const theme = useTheme();
  const dispatch = useDispatch();
  const {
    isDocumentViewerOpen,
  } = useSelector((state) => state.chat);
  const users1 = [
    {
      id: 1,
      first_name: 'Jahanzeb',
      last_name: 'Warraich',
      email: 'jahanzeb.warraich@northbaysolutions.net',
      isActive: true,
    },
    {
      id: 2,
      first_name: 'Waqar',
      last_name: 'Shakeel',
      email: 'waqar.shakeel@northbaysolutions.net',
      isActive: false,
    },
  ];
  const { page, limit, query } = tableParams;

  const [openChatHistory, setOpenChatHistory] = useState<boolean>(false);
  const [userSessions, setUserSessions] = useState<any>(null);
  const [conversations, setConversations] = useState<any>(null);
  const [selectedSessionId, setSelectedSessionId] = useState<number>(null);
  const [currentChatSession, setCurrentChatSession] = useState(null);
  const [selectedChatId, setSelectedChatId] = useState<string>(null);
  const [selectedUserName, setSelectedUserName] = useState<string>('');
  const [selectedSessionEndDate, setSelectedSessionEndDate] = useState<string>(null);
  const [selectedSessionStartDate, setSelectedSessionStartDate] = useState<string>(null);
  const [sessionId, setSessionId] = useState<string>(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const [isReadyToRender, setIsReadyToRender] = useState(false);
  const [expandedEmails, setExpandedEmails] = useState({});

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    setIsReadyToRender(false);
    // Reload sessions based on the selected tab
    if (selectedUserName) {
      const userId = users.find(user => (user.first_name + ' ' + user.last_name) === selectedUserName)?.external_user_id;
      const userEmail = users.find(user => (user.first_name + ' ' + user.last_name) === selectedUserName)?.email;

      if (userId) {
        loadUserSessions(userId, newValue, userEmail);
        setTimeout(() => {
          setIsReadyToRender(true);
        }, 2000);
      }
    }
  };
  const handleExpandClick = (email) => {
    setExpandedEmails((prev) => ({
      ...prev,
      [email]: !prev[email], // Toggle the expanded state for the email
    }));
  };
  const [isLoadingConversations, setIsLoadingConversations] =
    useState<boolean>(false);

  const debouncedGetUsers = useCallback(debounce(getUsers, 500), []); // Adjust the debounce delay as needed (e.g., 500 milliseconds)

  const handleQueryChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const newValue = event.target.value;
    if (!isEqual(query, newValue)) {
      // debouncedGetUsers(0, limit, newValue);
      setTableParams((prevParams) => ({
        ...prevParams,
        page: 0,
        query: newValue,
      }));
    }
  };

  const handlePageChange = (
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ): void => {
    getUsers(newPage, limit, query);
    setTableParams((prevParams) => ({ ...prevParams, page: newPage }));
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const updatedLimit = parseInt(event.target.value, 10);
    getUsers(page, updatedLimit, query);
    setTableParams((prevParams) => ({ ...prevParams, limit: updatedLimit }));
  };

  const getUserStatus = (userStatus: boolean): JSX.Element => {
    const colorCode = userStatus ? 'success' : 'error';
    return <Label color={colorCode}>{userStatus ? 'Yes' : 'No'}</Label>;
  };

  const loadUserSessions = async (userId: string, tab: number, userEmail: string) => {
    const includeEmail = tab !== 1;
    const sessions = await userChatSessions(userId, includeEmail).then((resp) => resp.data);
    if (sessions?.length !== 0) {
      let result = [];
      if (tab === 1) {
        result = sessions;
      } else {
        const groupedSessions = sessions.reduce((acc, session) => {
          const email = session.email || userEmail;
          if (!acc[email]) {
            acc[email] = [];
          }
          acc[email].push(session);
          return acc;
        }, {});
        result = Object.keys(groupedSessions).map(email => ({
          email,
          sessions: groupedSessions[email],
        }));
      }
      setUserSessions(result);
      setOpenChatHistory(true);
    } else {
      toast.error('No session found');
    }
  };

  const loadConversationsBySessionAndChatId = async (
    sessionId: number,
    chatId: string,
    sessionStringId,
    sessionStartDate: string,
    sessionEndDate: string,
  ) => {
    setSelectedSessionId(sessionId);
    setSelectedChatId(chatId);
    setIsLoadingConversations(true);
    setSessionId(sessionStringId);
    setSelectedSessionEndDate(new Date(sessionEndDate).toLocaleTimeString());
    setSelectedSessionStartDate(new Date(sessionStartDate).toLocaleTimeString());
    setConversations(null);
    const conversations = await getConversationBySessionAndChatId(
      sessionId,
      chatId
    )
      .then((resp) => resp?.data)
      .catch((err) => {
        setIsLoadingConversations(false);
      });

    setConversations(conversations);
    setIsLoadingConversations(false);
  };
  const getStyles = () => {
    if (
      isLoadingConversations ||
      (selectedSessionId && (!conversations || !conversations.length))
    ) {
      return {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      };
    }

    return {};
  };

  const [documentUrl, setDocumentUrl] = useState<string>('');
  const [sourceDocs, setSourceDocs] = useState<string>('');
  const openPdfSection = (url: string, sourceDocs: any) => {
    // setIsSplit(false);
    setDocumentUrl(url);
    setSourceDocs(sourceDocs);
    dispatch(toggleIsDocumentViewerOpen(true));
  };
  const getGroupedSourceDocuments = (documents = []) => {
    const groupedDocs = {};
    documents.forEach(([doc, euclideanDistance]) => {
      if (!groupedDocs[doc.metadata.url]) {
        groupedDocs[doc.metadata.url] = [];
      }

      groupedDocs[doc.metadata.url].push({ ...doc, euclideanDistance });
    });

    return groupedDocs;
  };

  return (
    <Card {...other}>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexWrap: 'wrap',
          m: -1,
          p: 2,
        }}
      >
        <Box
          sx={{
            m: 1,
            width: '100%',
          }}
        >
          <h2 className='minus-pb10' style={{ color: '#43B6E1', background: 'white' }}>Users Detail</h2>
          <TextField
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon fontSize="small" />
                </InputAdornment>
              ),
            }}
            onChange={handleQueryChange}
            placeholder="Search users"
            value={query}
            variant="outlined"
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: 2,
              },
              '& .MuiOutlinedInput-notchedOutline': {
                borderRadius: 2,
              },
              width: 'calc(100% - 10px)',
            }}
          />
        </Box>
      </Box>

      <Box sx={{ p: 2 }} className="pd-box-top0">
        <TableContainer>
          {loading ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Scrollbar>
                <Box sx={{ minWidth: 700 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={TableHeaderCellStyle}>Name</TableCell>
                        <TableCell sx={TableHeaderCellStyle}>
                          USERNAME
                        </TableCell>
                        <TableCell sx={TableHeaderCellStyle}>
                          DESIGNATION
                        </TableCell>
                        <TableCell sx={TableHeaderCellStyle}>
                          DEPARTMENT
                        </TableCell>
                        <TableCell sx={TableHeaderCellStyle}>
                          DIVISION
                        </TableCell>

                        {/* <TableCell sx={TableHeaderCellStyle} align="center">Active Session</TableCell> */}
                        <TableCell sx={TableHeaderCellStyle} align="center">
                          SESSION HISTORY
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {users.map((user) => (
                        <TableRow hover key={user.id}>
                          <TableCell sx={TableCellStyle}>
                            {user.first_name + ' ' + user.last_name}
                          </TableCell>
                          <TableCell sx={TableCellStyle}>
                            {user.email}
                          </TableCell>

                          <TableCell sx={TableCellStyle}>
                            {user.designation_name}
                          </TableCell>
                          <TableCell sx={TableCellStyle}>
                            {user.department_name}
                          </TableCell>
                          <TableCell sx={TableCellStyle}>
                            {user.division_name}
                          </TableCell>

                          {/* <TableCell sx={TableCellStyle} align="center">{getUserStatus(user.is_session_active)}</TableCell> */}
                          <TableCell align="center" sx={TableCellStyle}>
                            <IconButton
                              sx={{ padding: '0px' }}
                              onClick={() => {
                                setSelectedUserName(user.first_name + ' ' + user.last_name);
                                setCurrentChatSession(null);
                                setSelectedSessionId(null);
                                loadUserSessions(user.external_user_id, 0, user.email);
                              }}
                            >
                              <PreviewIcon
                                sx={{
                                  color: user.is_session_active
                                    ? '#43B6E1 !important'
                                    : 'grey !important',
                                }}
                              />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              </Scrollbar>
            </>
          )}
        </TableContainer>
        <TablePagination
          component="div"
          count={users?.count || 0}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25]}
          labelRowsPerPage={false}
        />
      </Box>
      <Dialog
        open={openChatHistory}
        BackdropProps={{ style: { opacity: 0.5 } }}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle sx={{ pb: 0 }}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <div>
              <Typography style={{ display: 'inline-block', color: '#333333', fontWeight: 700 }} component="h1">
                Sessions:
              </Typography>
              <Typography
                style={{ display: 'inline-block', fontWeight: 'bold' }}
                component="h1"
              >
                &nbsp;{`${selectedUserName.toUpperCase()}`}
              </Typography>
            </div>
            {(currentChatSession?.userName && currentChatSession?.email) && <div style={{ display: 'flex', paddingTop: '8px' }}>
              <Typography style={{ fontSize: '0.75rem', color: '#2B5D8C', marginRight: '20px' }} component="h1">
                UserName: <b style={{ color: '#000000' }}>{currentChatSession.userName}</b>
              </Typography>
              <Typography style={{ fontSize: '0.75rem', color: '#2B5D8C' }} component="h1">
                Email: <b style={{ color: '#000000' }}>{currentChatSession.email}</b>
              </Typography>
            </div>}
            {selectedSessionId && <div style={{ fontSize: '0.75rem' }}>
              <Typography component="b" style={{ fontSize: '0.75rem', color: '#333333' }}>
                Session ID: <b style={{ color: '#333333' }}>{sessionId}</b>
              </Typography>
              <br />
              <span>
                <Typography component="span" style={{ fontSize: '0.75rem', color: '#333333' }}>
                  Start Time: <b style={{ color: '#333333' }}>{selectedSessionStartDate}</b>
                </Typography>
                <Typography component="span" style={{ marginLeft: '0.5rem', fontSize: '0.75rem', color: '#333333' }}>
                  End Time: <b style={{ color: '#333333' }}>{selectedSessionEndDate}</b>
                </Typography>
              </span>
            </div>}
          </div>
          <Divider sx={{ width: '100%', borderWidth: '1px', mt: '10px' }} />
        </DialogTitle>
        <DialogContent sx={{ pt: 0 }}>
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            sx={{
              marginTop: '10px',
              marginBottom: '3px',
              minWidth: '150px !important',
              height: '10px !important',
              '& .MuiTabs-indicator': {
                backgroundColor: '#006CB7',
              },
              '& .MuiTabs-fixed': {
                height: '45px',
              },
            }}
          >
            <Tab
              label="View by Users"
              sx={{
                minWidth: '140px !important',
                fontSize: '0.875rem',
                height: '10px !important',
                padding: '0px',
                color: '#333333',
                '&.Mui-selected': {
                  color: '#FFFFFF',
                  backgroundColor: '#006CB7',
                },
                '&:hover': {
                  color: 'black',
                  backgroundColor: '#D3D3D3',
                },
              }}
            />
            <Tab
              label="View by Date"
              sx={{
                fontSize: '0.875rem',
                minWidth: '145px !important',
                height: '10px !important',
                padding: '0px',
                color: '#333333',
                '&.Mui-selected': {
                  color: '#FFFFFF',
                  backgroundColor: '#006CB7',
                },
                '&:hover': {
                  color: 'black',
                  backgroundColor: '#D3D3D3',
                },
              }}
            />
          </Tabs>
          <Box width="100%" sx={{ display: 'flex', height: '58vh' }}>
            <Box
              width="300px"
              sx={{
                borderRight: '1px solid #ccc',
                height: '100%',
                overflow: 'hidden',
              }}
            >
              <List
                sx={{
                  width: '100%',
                  background: '#FDFDFD',
                  height: '100%',
                  overflow: 'auto',
                }}
              >
                {selectedTab === 1 &&
                  userSessions && isReadyToRender && (
                    Array.isArray(userSessions) && userSessions.length > 1 ? (
                      userSessions.map((session) => (
                        <HierarchialListItem
                          key={session.id}
                          loadConversationsBySessionAndChatId={loadConversationsBySessionAndChatId}
                          setCurrentChatSession={setCurrentChatSession}
                          setConversations={setConversations}
                          setSelectedSessionId={setSelectedSessionId}
                          selectedSessionId={selectedSessionId}
                          selectedChatId={selectedChatId}
                          setSelectedChatId={setSelectedChatId}
                          sessionStartDate={session.start_datetime}
                          sessionEndDate={session.end_datetime}
                          setSelectedSessionStartDate={setSelectedSessionStartDate}
                          setSelectedSessionEndDate={setSelectedSessionEndDate}
                          id={session.id.toString()}
                          sessionId={session.session_id}
                          setSessionId={setSessionId}
                          option={{
                            id: session.session_id,
                            label: new Date(session.start_datetime).toLocaleString('en-US', dateFormatOptions),
                            is_active: session.is_active,
                          }}
                        />
                      ))
                    ) : (
                      null
                    )
                  )}
                {selectedTab === 0 && Array.isArray(userSessions) && userSessions.length > 0 ? (
                  userSessions.map(({ email, sessions }) => (
                    <Box sx={{ mb: 2 }}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <IconButton onClick={() => handleExpandClick(email)} sx={{ p: 0, mr: 1 }}>
                          {expandedEmails[email] ? <RemoveIcon sx={{ color: 'black !important', border: '1px solid #000000' }} /> : <AddIcon sx={{ color: 'black !important', border: '1px solid #000000' }} />}
                        </IconButton>
                        <Typography variant="body2" sx={{ color: 'black' }}>
                          {email}
                        </Typography>
                      </Box>
                      {expandedEmails[email] && (
                        <Box sx={{ ml: 2 }}>
                          {sessions.map((session) => (
                            <HierarchialListItem
                              key={session.id}
                              loadConversationsBySessionAndChatId={loadConversationsBySessionAndChatId}
                              setCurrentChatSession={setCurrentChatSession}
                              setConversations={setConversations}
                              setSelectedSessionId={setSelectedSessionId}
                              selectedSessionId={selectedSessionId}
                              selectedChatId={selectedChatId}
                              setSelectedChatId={setSelectedChatId}
                              sessionStartDate={session.start_datetime}
                              sessionEndDate={session.end_datetime}
                              setSelectedSessionStartDate={setSelectedSessionStartDate}
                              setSelectedSessionEndDate={setSelectedSessionEndDate}
                              id={session.id.toString()}
                              sessionId={session.session_id}
                              setSessionId={setSessionId}
                              option={{
                                id: session.session_id,
                                label: new Date(session.start_datetime).toLocaleString('en-US', dateFormatOptions),
                                is_active: session.is_active,
                              }}
                              email={email}
                            />
                          ))}
                        </Box>
                      )}
                    </Box>
                  ))
                ) : (
                  null
                )}
              </List>
            </Box>
            <Box
              sx={{
                width: 'calc(100% - 300px)',
                height: '100%',
                overflow: 'hidden',
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                  overflow: 'auto',
                  display: 'flex',
                  flexDirection: 'column',
                  ...getStyles(),
                }}
              >
                <Box>
                  {conversations &&
                    conversations.map(({ question, answer, id, external_conv_id, source_documents }: any) => {
                      let groupedSourceDocuments = getGroupedSourceDocuments(source_documents);
                      return (
                        <>
                          <ChatMessage
                            body={question}
                            loader={null}
                            showIcons={true}
                            key={`questionMessageId${id}`}
                            thread={''}
                            threadKey={''}
                            onPdfDocClick={openPdfSection}
                            senderAvatar={
                              '/static/mock-images/avatars/avatar-jane_rotanson.png'
                            }
                            senderType={'user'}
                            msgLoading={false}
                          />

                          <ChatMessage
                            body={answer}
                            source_documents={groupedSourceDocuments}
                            loader={null}
                            msgLoading={false}
                            showIcons={true}
                            externalConvId={external_conv_id}
                            loadAtOnce
                            key={`answerMessageId${id}`}
                            msgId={id}
                            thread={''}
                            threadKey={''}
                            senderAvatar={
                              '/static/mock-images/avatars/avatar-jane_rotanson.png'
                            }
                            senderType={'dewa'}
                            onPdfDocClick={openPdfSection}
                          />
                        </>
                      )
                    })}
                  {selectedSessionId &&
                    selectedChatId &&
                    !isLoadingConversations &&
                    (!conversations || !conversations.length) && (
                      <Typography variant="h4">
                        No conversation history found.
                      </Typography>
                    )}
                  {isLoadingConversations && (
                    <CircularProgress
                      style={{ color: theme.palette.primary.main, width: '4rem', height: '4rem' }}
                    />
                  )}
                </Box>
                {isDocumentViewerOpen && (<Divider orientation="vertical" flexItem />)}
                {isDocumentViewerOpen && (

                  <Box>
                    <ChatDocumentViewer
                      url={documentUrl}
                      setIsSplit={(val: boolean) => {
                        dispatch(toggleIsDocumentViewerOpen(val));
                      }}
                      sourceDocuments={sourceDocs}>
                    </ChatDocumentViewer>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
          <Divider sx={{ width: '100%', borderWidth: '1px' }} />
        </DialogContent>
        <DialogActions sx={{ pr: 3, pb: 2 }}>
          <Button
            className="btn-document admin-closeButton"
            onClick={() => {
              setSelectedChatId(null);
              setConversations(null);
              setOpenChatHistory(false);
            }}
            sx={{ fontSize: '16px !important' }}
          >
            <img
              src={CloseIcon}
              alt="CloseIcon"
              style={{ marginRight: '5px', width: '12px' }}
            />
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Card >
  );
};

UsersListTable.propTypes = {
  users: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  getUsers: PropTypes.func.isRequired,
  tableParams: PropTypes.any.isRequired,
  setTableParams: PropTypes.func.isRequired,
};

export default UsersListTable;
